import React from "react";
import PropTypes from "prop-types";

const ThemeContext = React.createContext();

const ThemeProvider = ({ children }) => {
  const [themename, setthemename] = React.useState(
    JSON.parse(localStorage.getItem("theme")) || true
  );
  const toggeltheme = () => {
    setthemename((prev) => !prev);
    localStorage.setItem("theme", JSON.stringify(!themename));
  };

  return (
    <ThemeContext.Provider value={[{ themename, toggeltheme }]}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ThemeProvider, ThemeContext };
