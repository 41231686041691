import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Button } from "@chakra-ui/react";
import HighlightModel from "./HighlightModel";
import { ThemeContext } from "../../../Context/theme";

const PlayerCardPadel = ({
  scoreArray,
  matchId,
  el,
  selectedActions,
  setSelectedActions,
  getDataMain,
  change,
  teamId,
  tournamentId,
  eventId,
  tie,
  game,
  set,
}) => {
  const [score, setScore] = useState(0);
  const referee = JSON.parse(localStorage.getItem("referee_module_referee"));

  useEffect(() => {
    setScore(getTotalPointsById(el._id));
  }, [change, scoreArray]);
  const [{ themename }] = React.useContext(ThemeContext);

  
  const handleAction = (title, points) => {
    const newAction = {
      userId: referee,
      matchId: matchId,
      playerId: el._id,
      title: title,
      points: points,
      teamId: teamId,
      tournamentId,
      eventId,
      setKey: set,
      gameKey: game,
      tieKey: tie,
    };

    // Check if the action is already selected
    const isSelected = selectedActions.some((action) => action.title === title);

    if (isSelected) {
      // If selected, remove it
      const updatedActions = selectedActions.filter(
        (action) => action.title !== title
      );
      setScore((prev) => prev - points);

      setSelectedActions(updatedActions);
    } else {
      // If not selected, add it
      setScore((prev) => prev + points);

      setSelectedActions([...selectedActions, newAction]);
    }
  };

  function getTotalPointsById(playerId) {
    const result = scoreArray.find((entry) => entry._id === playerId);

    if (result) {
      return result.totalPoints;
    } else {
      return 0;
    }
  }

  return (
    <div className={styles.btnDiv10}>
      <div id={themename?styles.ProfileDiv_dark :styles.ProfileDiv}>
        <span>
          <img src={el.profileImg} alt="" />
        </span>
        <p className={themename?styles.textNamePlayer_dark :styles.textNamePlayer}>
          {el.name} #{el.rank}
        </p>

        <HighlightModel
          score={score}
          matchId={matchId}
          playerId={el._id}
          getDataMain={getDataMain}
        />
      </div>

      <div className={styles.upperBtnDiv}>
        <div className={styles.btnDiv1}>
          <span>
            <Button
              bg={
                selectedActions.some(
                  (action) => action.title === "Wall Descent"
                )
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              borderRadius="50%"
              _hover={{ bg: "0373EA" }}
              onClick={() => handleAction("Wall Descent", 2)}
            >
              +2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Wall Descent</p>
          </span>

          <span>
            <Button
              bg={
                selectedActions.some((action) => action.title === "Body Bag")
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              borderRadius="50%"
              _hover={{ bg: "0373EA" }}
              onClick={() => handleAction("Body Bag", 2)}
            >
              +2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Body Bag</p>
          </span>

          <span>
            <Button
              bg={
                selectedActions.some(
                  (action) => action.title === "Out Of Bounds Recovery"
                )
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              borderRadius="50%"
              _hover={{ bg: "0373EA" }}
              onClick={() => handleAction("Out Of Bounds Recovery", 2)}
            >
              +2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>OBR</p>
          </span>
          <span>
            <Button
              bg={
                selectedActions.some((action) => action.title === "Winner")
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              borderRadius="50%"
              _hover={{ bg: "0373EA" }}
              onClick={() => handleAction("Winner", 2)}
            >
              +2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Winner</p>
          </span>

          <span>
            <Button
              bg={
                selectedActions.some((action) => action.title === "Match Won")
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              _hover={{ bg: "0373EA" }}
              borderRadius="50%"
              onClick={() => handleAction("Match Won", 10)}
            >
              +10
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Match won</p>
          </span>

          <span>
            <Button
              bg={
                selectedActions.some((action) => action.title === "Team Win")
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              _hover={{ bg: "0373EA" }}
              borderRadius="50%"
              onClick={() => handleAction("Team Win", 2)}
            >
              +2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Team Win</p>
          </span>
        </div>

        <div className={styles.btnDiv2}>
          <span>
            <Button
              bg={
                selectedActions.some(
                  (action) => action.title === "Unforced Error"
                )
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              borderRadius="50%"
              _hover={{ bg: "0373EA" }}
              onClick={() => handleAction("Unforced Error", -2)}
            >
              -2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Unforced Error</p>
          </span>

          <span>
            <Button
              bg={
                selectedActions.some((action) => action.title === "Team Loss")
                  ? "green"
                  : "#0373EA"
              }
              color="#fff"
              borderRadius="50%"
              _hover={{ bg: "0373EA" }}
              onClick={() => handleAction("Team Loss", -2)}
            >
              -2
            </Button>
            <p className={themename?styles.neme_para_card_dark :styles.neme_para_card}>Team Loss</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlayerCardPadel;
