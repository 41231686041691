import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import styles from "./styles.module.css";
import trophy from "../../../assets/Dashboard/trophy.gif";
import { userRequest } from "../../../requestMethod";
import { useNavigate } from "react-router-dom";

const EndMatchPadel = ({ loading, btn ,setLoading,determineWinner,id}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate=useNavigate()
  

  const endMatch = () => {
    const result = determineWinner();
    if (result) {
      setLoading(true);
      userRequest
        .put(`/api/match/endMatch?id=${id}`, {
          end: true,
          winner: result,
        })
        .then(() => {
          setLoading(false);
          onClose()
          navigate("/")
          
        });
    }
  };

  return (
    <>
      <Button
        disabled={btn}
        isLoading={loading}
        onClick={onOpen}      
        colorScheme='messenger'
        w="130px"
        borderRadius="10px"
        zIndex={100}
      >
        End Match
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <div className={styles.deleteModeldiv}>
            <img src={trophy} alt="" className="" />
            <p>Are your sure want to end match?</p>
          </div>

          <ModalFooter>
            <div className={styles.modelFooter}>
              <Button mr={3} onClick={() => endMatch()}>
                Yes
              </Button>
              <Button variant="outline" color="black" onClick={onClose}>
                No
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EndMatchPadel;
