import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./AuthReducer";
import matchReducer from "./MatchReducer";


export default configureStore({
  reducer: {
    auth: authReducer,
    match: matchReducer,
  },
});
