import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import Login from "../Pages/Auth/Login/Login";
import Score from "../Pages/Score/Score";
import PadelScoring from "../Pages/Score/EditScore/PadelScoring";
import PickleBallScoring from "../Pages/Score/EditScore/PickelBallScoring";



const Router = () => {
  const token = getTokenFromCookie();
  const navigate = useNavigate()
  const location = useLocation();


  function getTokenFromCookie() {
    const cookieString = document.cookie;
    const cookies = cookieString.split(";");

    for (const cookie of cookies) {
      const [name, value] = cookie.split("=").map((c) => c.trim());
      if (name === "token") {
        return value;
      }
    }

    return null; // Return null if the token cookie is not found
  }
  useEffect(() => {
    // Check if token exists
    if (token && location.pathname === "/login") {
      // Redirect user to the desired route
      navigate("/");
    }
  }, [location.pathname, token, navigate])

  const routes = [
 
    { path: "/", element: <PrivateRoute>< Score /></PrivateRoute> },
    { path: "/editScore/:id", element: <PrivateRoute>< PickleBallScoring /></PrivateRoute> },
    { path: "/padelScoring/:id", element: <PrivateRoute>< PadelScoring /></PrivateRoute> },

    { path: "/login", element: <Login /> },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default Router;
