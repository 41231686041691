import { useLocation } from "react-router-dom";
import "./App.css";
import "./style/styles.scss";
import Router from "./Components/Router";
import Home from "./Pages/Home/Home";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname === "/login" ? (
        <>
          <Router />
        </>
      ) : (
        <Home />
      )}
    </div>
  );
}

export default App;
